/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /users/sign_in(.:format) */
export function newUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function userSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function destroyUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/google_oauth2(.:format) */
export function userGoogleOauth2OmniauthAuthorizePath(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "google_oauth2" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/google_oauth2/callback(.:format) */
export function userGoogleOauth2OmniauthCallbackPath(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "google_oauth2" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/new(.:format) */
export function newUserPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/edit(.:format) */
export function editUserPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password(.:format) */
export function userPasswordPath(options?: object): string {
  return "/" + "users" + "/" + "password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/cancel(.:format) */
export function cancelUserRegistrationPath(options?: object): string {
  return "/" + "users" + "/" + "cancel" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_up(.:format) */
export function newUserRegistrationPath(options?: object): string {
  return "/" + "users" + "/" + "sign_up" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/edit(.:format) */
export function editUserRegistrationPath(options?: object): string {
  return "/" + "users" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users(.:format) */
export function userRegistrationPath(options?: object): string {
  return "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/unlock/new(.:format) */
export function newUserUnlockPath(options?: object): string {
  return "/" + "users" + "/" + "unlock" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/unlock(.:format) */
export function userUnlockPath(options?: object): string {
  return "/" + "users" + "/" + "unlock" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /google/:iidxid(.:format) */
export function googlePath(iidxid: ScalarType, options?: object): string {
  return "/" + "google" + "/" + iidxid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["iidxid","format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /users(.:format) */
export function usersPath(options?: object): string {
  return "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/:id(.:format) */
export function userPath(id: ScalarType, options?: object): string {
  return "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /messages(.:format) */
export function messagesPath(options?: object): string {
  return "/" + "messages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /messages/new(.:format) */
export function newMessagePath(options?: object): string {
  return "/" + "messages" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /helps/support(.:format) */
export function supportHelpsPath(options?: object): string {
  return "/" + "helps" + "/" + "support" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /helps/ist(.:format) */
export function istHelpsPath(options?: object): string {
  return "/" + "helps" + "/" + "ist" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /helps/oauth(.:format) */
export function oauthHelpsPath(options?: object): string {
  return "/" + "helps" + "/" + "oauth" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rival/list(.:format) */
export function listRivalPath(options?: object): string {
  return "/" + "rival" + "/" + "list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rival/reverse_list(.:format) */
export function reverseListRivalPath(options?: object): string {
  return "/" + "rival" + "/" + "reverse_list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rival/clear/:id(.:format) */
export function clearRivalPath(id: ScalarType, options?: object): string {
  return "/" + "rival" + "/" + "clear" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rival/hard/:id(.:format) */
export function hardRivalPath(id: ScalarType, options?: object): string {
  return "/" + "rival" + "/" + "hard" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rival/remove/:id(.:format) */
export function removeRivalPath(id: ScalarType, options?: object): string {
  return "/" + "rival" + "/" + "remove" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rival/register/:id(.:format) */
export function registerRivalPath(id: ScalarType, options?: object): string {
  return "/" + "rival" + "/" + "register" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rivals/:rival_id/reverse(.:format) */
export function rivalReversePath(rival_id: ScalarType, options?: object): string {
  return "/" + "rivals" + "/" + rival_id + "/" + "reverse" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["rival_id","format"]);
}

/** /sheets/:iidxid/:type(.:format) */
export function sheetPath(iidxid: ScalarType, type: ScalarType, options?: object): string {
  return "/" + "sheets" + "/" + iidxid + "/" + type + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["iidxid","type","format"]);
}

/** /scores/:id/edit(.:format) */
export function editScorePath(id: ScalarType, options?: object): string {
  return "/" + "scores" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /scores/:id(.:format) */
export function scorePath(id: ScalarType, options?: object): string {
  return "/" + "scores" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id/list(.:format) */
export function listLogPath(id: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + "/" + "list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id/sheet(.:format) */
export function sheetLogPath(id: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + "/" + "sheet" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id/ist(.:format) */
export function istLogPath(id: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + "/" + "ist" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id/edit(.:format) */
export function editLogPath(id: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id(.:format) */
export function logPath(id: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /logs/:id/:date(.:format) */
export function logsPath(id: ScalarType, date: ScalarType, options?: object): string {
  return "/" + "logs" + "/" + id + "/" + date + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","date","format"]);
}

/** /recommends(.:format) */
export function recommendsPath(options?: object): string {
  return "/" + "recommends" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/abilities(.:format) */
export function apiV1AbilitiesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "abilities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/users/status(.:format) */
export function statusApiV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/users/me(.:format) */
export function meApiV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "me" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/messages(.:format) */
export function apiV1MessagesPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "messages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/logs/:log_iidxid/cal_heatmap(.:format) */
export function apiV1LogCalHeatmapPath(log_iidxid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "logs" + "/" + log_iidxid + "/" + "cal_heatmap" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["log_iidxid","format"]);
}

/** /api/v1/logs/:log_iidxid/:year/:month(.:format) */
export function apiV1Path(log_iidxid: ScalarType, year: ScalarType, month: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "logs" + "/" + log_iidxid + "/" + year + "/" + month + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["log_iidxid","year","month","format"]);
}

/** /api/v1/statics(.:format) */
export function apiV1StaticsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "statics" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/sheets(.:format) */
export function apiV1SheetsPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "sheets" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/sheets/list(.:format) */
export function apiV1SheetsListPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "sheets" + "/" + "list" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/scores/:iidxid(.:format) */
export function apiV1ScorePath(iidxid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "scores" + "/" + iidxid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["iidxid","format"]);
}

/** /api/v1/maintenance(.:format) */
export function apiV1MaintenancePath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "maintenance" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/health_check(.:format) */
export function apiV1HealthCheckIndexPath(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "health_check" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /recede_historical_location(.:format) */
export function turboRecedeHistoricalLocationPath(options?: object): string {
  return "/" + "recede_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /resume_historical_location(.:format) */
export function turboResumeHistoricalLocationPath(options?: object): string {
  return "/" + "resume_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /refresh_historical_location(.:format) */
export function turboRefreshHistoricalLocationPath(options?: object): string {
  return "/" + "refresh_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format) */
export function railsConductorInboundEmailIncineratePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "incinerate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
